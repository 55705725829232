import React, { useState } from "react";
import Loader from "../Loader";
import { ToastContainer } from "react-toastify";
import { UserAddOutlined } from "@ant-design/icons";
import { Button } from "antd";

const InviteUserForm = ({
  platFormData,
  formValues,
  setFormValues,
  isSubmitting,
  handleSubmit,
}) => {
  const onChange = (e, key) => {
    setFormValues({ ...formValues, [key]: e });
  };

  const [selectedProduct, setSelectedProduct] = useState([]);

  const handleProductSelect = (value) => {
    let product = selectedProduct;
    if (selectedProduct.includes(value)) {
      product = product.filter((item) => item !== value);
    } else {
      product.push(value);
    }
    setSelectedProduct([...product]);
    setFormValues({ ...formValues, plateformId: product });
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <div className="tw-p-[20px]">
        <div className="tw-flex tw-justify-center tw-py-[20px]">
          <img src="/assets/1.png" alt="Tasc logo" width="240px" />
        </div>
        <div className="tw-text-center">
          <span className="tw-text-[26px] tw-text-[#434343] tw-font-[700]">
            Invite team members
          </span>
        </div>
        <div className="tw-flex-[0_0_100%] tw-max-w-[100%] tw-w-full tw-border tw-rounded-lg tw-border-[#ddd] product-search-form tw-my-[20px]">
          <div className="tw-flex tw-items-center tw-mx-auto lg:tw-mx-[0px] tw-leading-none 2xl:tw-h-[40px] tw-h-[36px] tw-pl-[10px] tw-pr-[30px] tw-bg-white tw-rounded-lg tw-relative tw-max-w-[440px]">
            <div
              className="tw-flex tw-items-center tw-absolute"
              style={{ right: 0 }}
            ></div>
            <div className="" style={{ flex: "0 0 100%", maxWidth: "100%" }}>
              <input
                id="inviterEmail"
                name="inviterEmail"
                type="text"
                value={formValues.invited_user_email}
                onChange={(e) => {
                  onChange(e.target.value, "invited_user_email");
                }}
                placeholder="Enter Email *"
                className="tw-block product-input tw-w-full 2xl:tw-py-2 tw-p-0 tw-w-full tw-border-0 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-outline-[0px]"
              />
            </div>
          </div>
        </div>
        <div className="tw-py-[5px]">
          <span className="tw-font-[700] tw-text-[17px] tw-text-[#928F8F]">
            Choose to invite for a specific platform
          </span>
        </div>
        <div>
          {platFormData.length > 0 ? (
            <div className="tw-flex tw-gap-[20px] tw-flex-col tw-py-[10px]">
              {platFormData.map((item) => {
                return (
                  <div className="tw-leading-none tw-flex tw-items-center tw-gap-[10px]">
                    <label class="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={formValues.plateformId.includes(item.value)}
                        onChange={() => handleProductSelect(item.value)}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      className="tw-text-[17px] tw-text-[#928F8F] tw-cursor-pointer"
                      onClick={() => handleProductSelect(item.value)}
                    >
                      {item.label[0].toUpperCase() +
                        item.label.slice(1, item.label.length)}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (
            "No Product found"
          )}
        </div>
        <div className="tw-py-[20px]">
          <Button
            type="primary"
            className="tw-bg-[#28a745] !tw-p-[20px] hover:!tw-bg-[#28a745]"
            icon={<UserAddOutlined style={{ fontSize: "18px" }} />}
            onClick={handleSubmit}
          >
            Invite user
          </Button>
        </div>
        {/* {platFormData.length > 0 ? (
          <>
            <div className="tw-py-[20px]">
              <span className="tw-font-[700] tw-text-[17px] tw-text-[#928F8F]">
                Active users
              </span>
              <div>
                {invitedUsers.length > 0 &&
                  invitedUsers.map((item) => {
                    return <ActiveUserList activeUsers={item} />;
                  })}
              </div>
            </div>
            <div>
              <span className="tw-text-[17px] tw-text-[#928F8F]">
                ⚠️ This user will have access to the selected platforms. Only
                admins at <b>{localStorage.getItem("company")}</b> can revoke
                access at any time.
              </span>
            </div>
          </>
        ) : (
          ""
        )} */}
        <div>
          <span className="tw-text-[17px] tw-text-[#928F8F]">
            ⚠️ This user will have access to the selected platforms. Only admins
            at <b>{localStorage.getItem("company")}</b> can revoke access at any
            time.
          </span>
        </div>  
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default InviteUserForm;
