import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { UPDATE_PASSWORD } from "../api/ApiConstants";
import { fetcher } from "../api/ApiHook";
import Loader from "../Loader";

const CreatePassword = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    password: "",
    newPassword: "",
  });

  const buttonDisable = () => {
    if (userDetails.password === "") {
      return true;
    } else if (userDetails.newPassword === "") {
      return true;
    } else if (userDetails.newPassword !== userDetails.password) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    let url = UPDATE_PASSWORD;
    let method = "POST";
    let payload = JSON.stringify({
      password: userDetails.password,
      conf_password: userDetails.newPassword,
      token: queryParameters.get("token"),
    });
    try {
      setLoading(true);
      const response = await fetcher(url, method, payload);
      if (response.status) {
        toast.success(
          "Password changed Successfully, Redirecting to the login Page."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeUserDetails = (e, filter) => {
    setUserDetails({ ...userDetails, [filter]: e.target.value });
  };
  return (
    <div className="inc-auth-container">
      <div className="tw-container tw-mx-auto tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-text-center">
        {loading && <Loader />}
        <div className="cover-container tw-w-full tw-h-full tw-pb-2">
          <div>
            <div className="form-container tw-max-w-[440px] tw-bg-white tw-mx-auto">
              <div>
                <div>
                  <img
                    src="/newLogo.svg"
                    alt="TASC logo"
                    className="tw-max-w-[220px] tw-mx-auto"
                  />
                </div>
                <div className="fs-24 ff-ws tw-mb-2 text-inc-tundora tw-font-[600]">
                  Enter New Password
                </div>
              </div>
              <div className="form-auth tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[15px]">
                <div
                  className="input-group tw-flex-nowrap"
                  id="password-filled"
                >
                  <input
                    className="tw-w-full form-control !tw-rounded-l-[50px] !tw-rounded-r-[0px]"
                    id="input-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={userDetails.password}
                    onChange={(e) => handleChangeUserDetails(e, "password")}
                  />
                  <span
                    className="tw-text-xl encrypted tw-py-[5px] tw-px-[10px] tw-border tw-border-[#dee2e6]"
                    style={{
                      cursor: "pointer",
                      borderRadius: "0 50px 50px 0px",
                    }}
                  >
                    {showPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => setShowPassword(false)}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => setShowPassword(true)}
                      ></i>
                    )}
                  </span>
                </div>
                <div className="input-group flex-nowrap" id="password-filled">
                  <input
                    className="tw-w-full form-control !tw-rounded-l-[50px] !tw-rounded-r-[0px]"
                    id="input-password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="confirm password"
                    name="password"
                    value={userDetails.newPassword}
                    onChange={(e) => handleChangeUserDetails(e, "newPassword")}
                  />
                  <span
                    className="tw-text-xl encrypted tw-py-[5px] tw-px-[10px] tw-border tw-border-[#dee2e6]"
                    style={{
                      cursor: "pointer",
                      borderRadius: "0 50px 50px 0px",
                    }}
                  >
                    {showNewPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => setShowNewPassword(false)}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => setShowNewPassword(true)}
                      ></i>
                    )}
                  </span>
                </div>
                <button
                  id="login-button"
                  className="btn btn-to-link btn-secondary btn-gradiant tw-mt-6 tw-flex tw-items-center"
                  type="button"
                  disabled={buttonDisable()}
                  onClick={handleSubmit}
                >
                  <span className="tw-font-[700]">Change password</span>
                  <span
                    className="icon-btn_track"
                    style={{ marginLeft: "10px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </span>
                </button>
                <div className="tw-flex tw-justify-center tw-w-full tw-mt-2">
                  <a
                    href="/signin"
                    className="tw-text-[13px] tw-underline text-inc-tundora"
                  >
                    Back to login?
                  </a>
                </div>
                <div
                  className="tw-mt-3 tw-text-[13px] ff-ws"
                  style={{ color: "grey" }}
                >
                  Powered by TASC Outsourcing®
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default CreatePassword;
