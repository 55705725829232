import React from "react";
// import { Loading } from "../../assets/Icons";

const Loader = () => {
  return (
    <div id="loading-overlay">
      <div className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          width="80"
          height="80"
          style={{shapeRendering: 'auto', display: 'block', background: 'transparent'}}
        //   xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <circle
              stroke-width="2"
              stroke="#005f83"
              fill="none"
              r="0"
              cy="50"
              cx="50"
            >
              <animate
                begin="0s"
                calcMode="spline"
                keySplines="0 0.2 0.8 1"
                keyTimes="0;1"
                values="0;40"
                dur="1s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="0s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                keyTimes="0;1"
                values="1;0"
                dur="1s"
                repeatCount="indefinite"
                attributeName="opacity"
              ></animate>
            </circle>
            <circle
              stroke-width="2"
              stroke="#2f9dc7"
              fill="none"
              r="0"
              cy="50"
              cx="50"
            >
              <animate
                begin="-0.5s"
                calcMode="spline"
                keySplines="0 0.2 0.8 1"
                keyTimes="0;1"
                values="0;40"
                dur="1s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="-0.5s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                keyTimes="0;1"
                values="1;0"
                dur="1s"
                repeatCount="indefinite"
                attributeName="opacity"
              ></animate>
            </circle>
            <g></g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
