import React from "react";
import {
  Calendar,
  GovernifyLogo,
  IncorpifyLogo,
  OnboardifyLogo,
  StatusIcon,
} from "../icons/Icons";
import { getDateAndTime } from "./utils/formatTime";
import { toast, ToastContainer } from "react-toastify";

const StepCard = ({ item, accessLabels }) => {
  function showProductIcon(product) {
    if (product === "onboardify") {
      return <OnboardifyLogo />;
    }
    if (product === "incorpify") {
      return <IncorpifyLogo />;
    }
    if (product === "governify") {
      return <GovernifyLogo />;
    }
  }

  function statusColor() {
    let color = "#454545";
    accessLabels.map((label) => {
      if (label.status.toLowerCase() === item.status.toLowerCase()) {
        color = label.color;
      }
    });

    return color;
  }

  return (
    <>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-border-b-[2px] tw-border-[#dee2e6] tw-gap-[10px] tw-pb-[16px] 2xl:tw-pb-[20px]">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="2xl:tw-text-[1.125rem] tw-text-[0.875rem] tw-flex tw-items-center tw-gap-[5px] ff-ws">
            <span>{showProductIcon(item.product)}</span>{" "}
            {item.hasAccess ? (
              <>
                {item.disable ? (
                  <span
                    className={`tw-text-[#434343] ff-ws ${
                      item.disable ? "tw-cursor-default" : "tw-cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!item.disable) {
                        toast.error(
                          "you do not have access to see details of this request, Kindly connect with your Admin"
                        );
                      }
                    }}
                  >
                    {item.category
                      ? item.category[0].toUpperCase() +
                        item.category.slice(1, item.category.length) +
                        " - "
                      : ""}
                    {item.name}
                  </span>
                ) : (
                  <a
                    href={item.path}
                    target="_blank"
                    className="tw-text-[#434343] ff-ws"
                    rel="noreferrer"
                  >
                    {item.category
                      ? item.category[0].toUpperCase() +
                        item.category.slice(1, item.category.length) +
                        " - "
                      : ""}
                    {item.name}
                  </a>
                )}
              </>
            ) : (
              <>
                {/* {item.disable ? ( */}
                  <span
                    className={`tw-text-[#434343] ff-ws ${
                      item.disable ? "tw-cursor-default" : "tw-cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!item.disable) {
                        toast.error(
                          "you do not have access to see details of this request, Kindly connect with your Admin"
                        );
                      }
                    }}
                  >
                    {item.category
                      ? item.category[0].toUpperCase() +
                        item.category.slice(1, item.category.length) +
                        " - "
                      : ""}
                    {item.name}
                  </span>
                {/* // ) : (
                //   <a
                //     href={item.path}
                //     target="_blank"
                //     className="tw-text-[#434343] ff-ws"
                //     rel="noreferrer"
                //   >
                //     {item.category
                //       ? item.category[0].toUpperCase() +
                //         item.category.slice(1, item.category.length) +
                //         " - "
                //       : ""}
                //     {item.name}
                //   </a>
                // )} */}
              </>
            )}
            {/* {item.disable || !item.hasAccess ? (
              <span
                className={`tw-text-[#434343] ff-ws ${
                  item.disable ? "tw-cursor-default" : "tw-cursor-pointer"
                }`}
                onClick={() => {
                  if (!item.disable) {
                    toast.error(
                      "you do not have access to see details of this request, Kindly connect with your Admin"
                    );
                  }
                }}
              >
                {item.category
                  ? item.category[0].toUpperCase() +
                    item.category.slice(1, item.category.length) +
                    " - "
                  : ""}
                {item.name}
              </span>
            ) : (
              <a
                href={item.path}
                target="_blank"
                className="tw-text-[#434343] ff-ws"
                rel="noreferrer"
              >
                {item.category
                  ? item.category[0].toUpperCase() +
                    item.category.slice(1, item.category.length) +
                    " - "
                  : ""}
                {item.name}
              </a>
            )} */}
          </span>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-gap-[10px] tw-w-full lg:tw-w-1/2">
          <span className="2xl:tw-text-[1.125rem] tw-text-[0.875rem] ff-ws tw-flex tw-items-center tw-gap-[5px]">
            <span>
              <Calendar />
            </span>{" "}
            <span className="tw-text-[#434343] ff-ws">
              {getDateAndTime(item.created_at)}
            </span>
          </span>
          <span
            className={`2xl:tw-text-[1.125rem] tw-text-[0.875rem] tw-mr-[5px] tw-w-[200px] ff-ws tw-flex tw-items-center tw-gap-[5px]`}
          >
            <span>
              <StatusIcon fill={statusColor()} />
            </span>
            <span className="tw-text-[#434343] ff-ws">{item.status}</span>
          </span>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
};

export default StepCard;
