export const countryList = [
  {
    code: "SA",
    name: "Saudi Arabia",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    code: "AQ",
    name: "Rest of Gulf",
  },
];
