export function getDateAndTime(time) {
    let date = new Date(time);
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "long" });
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();
  
    let newDate = `${day} ${month.slice(0, 3)} ${year} at ${hour}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
    return newDate;
  }

  export function getExpiryDateAndTime(time) {
    let date = new Date(time);
    const newDate = new Date(date.getTime() + 72 * 60 * 60 * 1000);
    let day = newDate.getDate();
    let month = newDate.toLocaleString("default", { month: "long" });
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
  
    let expireDate = `${day} ${month.slice(0, 3)} ${year} at ${hour}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
    return expireDate;
  }
  