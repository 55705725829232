import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isTokenValid, removeSessions } from "../utils/helper";

const AuthWrapper = () => {
  let token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const checkTokenIsValidOrNot = () => {
    let status = isTokenValid(token);
    if (status.valid) {
      // navigate("/");
    } else {
      removeSessions();
      navigate("/");
    }
  };
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      checkTokenIsValidOrNot();
    } else {
      navigate("/");
    }
  }, [token]);

  if(token === null || token === undefined || token === '') {
    navigate('/');
    return;
  }

  return <Outlet />;
};

export default AuthWrapper;
