import React from 'react'

const Footer = () => {
	return (
	  <footer
		className="text-white-50 tw-flex inc-main-footer"
	  >
		<div className="text-secondary inc-powered">
		  <small
			style={{
			  fontSize: "15px",
			  fontWeight: "400",
			  color: "#848484",
			//   fontFamily: "Graphie-Regular",
			}}
		  >
			Powered by TASC Outsourcing®
		  </small>
		</div>
		<div className="align-center text-secondary fs-6 governify-footer-wrapper">
		  <a
			target="blank"
			href="https://tascoutsourcing.sa/en/terms-of-use"
			style={{
			  fontWeight: "400",
			  fontSize: "15px",
			  color: "#848484",
			  textDecoration: 'underline'
			}}
		  >
			Terms of use
		  </a>
		  <a
			target="blank"
			href="https://tascoutsourcing.sa/en/privacy-policy"
			style={{
			  fontWeight: "400",
			  fontSize: "15px",
			  color: "#848484",
			  textDecoration: 'underline'
			}}
		  >
			Data Privacy
		  </a>
		  <a
			target="blank"
			href="https://tascoutsourcing.sa/en/disclaimer"
			style={{
			  fontWeight: "400",
			  fontSize: "15px",
			  color: "#848484",
			  textDecoration: 'underline'
			}}
		  >
			Policy Fraud
		  </a>
		  <a
			target="blank"
			href="https://tascoutsourcing.sa/en/fraud-scam-alert"
			style={{
			  fontWeight: "400",
			  fontSize: "15px",
			  color: "#848484",
			  textDecoration: 'underline'
			}}
		  >
			Scam Alert
		  </a>
		</div>
	  </footer>
	);
  };
  
  export default Footer;