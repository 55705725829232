import React, { useEffect, useState } from "react";
import { getCommonSiteSetting, getSiteSettings } from "../api/ApiHook";
import AppLayout from "../AppLayout";
import Loader from "../Loader";

const PanelWrapper = () => {
  const [fetching, setFetching] = useState(false);
  const [siteSetting, setSiteSetting] = useState([]);

  const fetchSiteSetting = async () => {
    setFetching(true);
    let data = await getSiteSettings();
    await getCommonSiteSetting();
    setSiteSetting(data);
    setTimeout(() => {
      setFetching(false);
    }, 2000);
  };

  useEffect(() => {
    if (
      localStorage.getItem("siteSetting") === null ||
      localStorage.getItem("siteSetting") === undefined
    ) {
      fetchSiteSetting();
    }
  }, []);

  if (fetching) {
    return <Loader />;
  }

  return (
    <>
      {fetching && <Loader />}
      <AppLayout data={siteSetting} />
    </>
  );
};
export default PanelWrapper;
