export const HOST_API = 'https://onboardifyapi.tasc360.com/';

export const GET_USER_DETAILS = HOST_API + 'loginUserDetails';
export const USER_LOGIN = HOST_API + 'commom-login';
export const USER_REGISTRATION = HOST_API + 'onboardify/newSignup';
export const FORGET_PASSWORD = HOST_API + 'commom-forgot';
export const VERIFY_USER = HOST_API + 'common/verifyUser';
export const UPDATE_PASSWORD = HOST_API + 'common/updateNewPassword';
export const GET_ALL_REQUEST = HOST_API + 'tasc360/getAllDomain';
export const GET_SITE_SETTING = HOST_API + 'tasc360/getTascSiteSettings';
export const SAVE_SITE_SETTING = HOST_API + 'tasc360/saveTascSiteSettings';
export const UPLOAD_IMAGE = HOST_API + 'tasc360/uploadTask360Images';
export const REMOVE_IMAGE = HOST_API + 'tasc360/deleteUploadedImage';
export const GET_COMMON_SITE_SETTING = HOST_API + 'tasc360/getCommonSiteSettings';
export const GET_PLATFORM_DATA = HOST_API + 'tasc360/getPlateformData';
export const SEND_INVITE = HOST_API + 'tasc360/sendInviteUserMail';
export const GET_USER_PROFILE_STATUS = HOST_API + 'tasc360/checkUserProfileStatus';
export const GET_INVITE_DATA = HOST_API + 'tasc360/getInviteData';
export const GET_ALL_PROFILE_WITH_SERVICE = HOST_API + 'newonboardify/customer/allProfileWithServicesByUser';
export const UPDATE_INVITE = HOST_API + 'tasc360/GrantOrRevokePermission';
export const SEND_OTP = HOST_API + 'governify/customer/sendOTP';
export const VERIFY_OTP = HOST_API + 'governify/customer/verifyOTP'