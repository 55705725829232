import { Button, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { fetcher, getSiteSettings } from "./api/ApiHook";
import {
  REMOVE_IMAGE,
  SAVE_SITE_SETTING,
  UPLOAD_IMAGE,
} from "./api/ApiConstants";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";

const AdminSetting = () => {
  let siteSetting = JSON.parse(localStorage.getItem("siteSetting"));
  const [uiSettings, setUiSettings] = useState({
    site_bg: "#ffffff",
    header_text_color: "#ffffff",
    header_bg: "#005f83",
    sidebar_bg: "#e7e7e7",
    header_logo: "",
    home_logo: "",
    incorpify_logo: "",
    onboardify_logo: "",
    governify_logo: "",
    activation_form: "",
  });
  const [statusFields, setStatusFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageFields, setImageFields] = useState([]);

  const addField = () => {
    let fields = statusFields;
    fields.push({ status: "", color: "#454545" });
    setStatusFields([...fields]);
  };

  const removeField = (key) => {
    let fields = statusFields;
    fields.splice(key, 1);
    setStatusFields([...fields]);
  };

  const changeStatus = useCallback(
    (key, value) => {
      setStatusFields(
        statusFields.map((field, index) =>
          index === key ? { ...field, status: value } : field
        )
      );
    },
    [statusFields]
  );

  const changeColor = useCallback(
    (key, value) => {
      setStatusFields(
        statusFields.map((field, index) =>
          index === key ? { ...field, color: value } : field
        )
      );
    },
    [statusFields]
  );

  const addImageField = useCallback(() => {
    setImageFields([...imageFields, { image: "", url: "", name: "" }]);
  }, [imageFields]);

  const deleteSliderImage = async (key) => {
    let payload = {
      image_key: key,
    };
    try {
      const res = await fetcher(REMOVE_IMAGE, "POST", JSON.stringify(payload));
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeImageFields = useCallback(
    (key, image_key, image) => {
      let fields = imageFields;
      fields.splice(key, 1);
      setImageFields([...fields]);
      if (image !== "") {
        deleteSliderImage(image_key);
      }
    },
    [imageFields]
  );

  const changeImage = (key, value) => {
    setUiSettings({ ...uiSettings, [key]: value });
  };

  const handleChangeLogo = async (e, key, url) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    let index = 0;
    let lastIndex = url.split("/").pop().split("_").pop();
    if (lastIndex.includes("0")) {
      index = 1;
    }
    if (file) {
      reader.onload = (function (theFile) {
        return async function (e) {
          let payload = {
            image_key: key + "_" + index,
            image: e.target.result,
          };
          let image = await fetcher(
            UPLOAD_IMAGE,
            "POST",
            JSON.stringify(payload)
          );
          if (image.success) {
            setUiSettings({ ...uiSettings, [key]: "" });
            changeImage(key, image.data.url);
          }
        };
      })(file);
      reader.readAsDataURL(file);
    }
  };

  const handleChangeColor = useCallback(
    (e, key) => {
      setUiSettings({ ...uiSettings, [key]: e.target.value });
    },
    [uiSettings]
  );

  const handleChangeSliderImage = async (e, key) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file) {
      reader.onload = (function (theFile) {
        return async function (e) {
          let payload = {
            image_key: file.name.replace(" ", "_"),
            image: e.target.result,
          };
          let image = await fetcher(
            UPLOAD_IMAGE,
            "POST",
            JSON.stringify(payload)
          );
          if (image.success) {
            setImageFields(
              imageFields.map((fields, index) =>
                index === key
                  ? {
                      ...fields,
                      image: image.data.url,
                      name: file.name.replace(" ", "_"),
                    }
                  : fields
              )
            );
          }
        };
      })(file);
      reader.readAsDataURL(file);
    }
  };

  const handleSliderUrls = useCallback(
    (value, key) => {
      setImageFields(
        imageFields.map((field, index) =>
          index === key ? { ...field, url: value } : field
        )
      );
    },
    [imageFields]
  );

  const validateForm = async () => {
    let valid = true;
    if (
      uiSettings.governify_logo === "" ||
      uiSettings.header_logo === "" ||
      uiSettings.home_logo === "" ||
      uiSettings.incorpify_logo === "" ||
      uiSettings.onboardify_logo === "" ||
      uiSettings.activation_form === ""
    ) {
      valid = false;
    }
    imageFields.map((item) => {
      if (item.image === "" || item.url === "") {
        valid = false;
      }
    });
    statusFields.map((item) => {
      if (item.status === "" || item.color === "") {
        valid = false;
      }
    });

    return valid;
  };

  const handleSubmit = async () => {
    const Valid = await validateForm();
    if (!Valid) {
      toast.error("All Fields are required");
      return;
    }
    setLoading(true);
    let payload = {
      ui_settings: uiSettings,
      quick_access: statusFields,
      slider_images: imageFields,
    };
    try {
      const response = await fetcher(
        SAVE_SITE_SETTING,
        "POST",
        JSON.stringify(payload)
      );
      if (response.success) {
        await getSiteSettings();
        setTimeout(() => {
          toast.success("Tasc360 Site setting saved successfully!");
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }, 3000);
      } else {
        setLoading(false);
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (siteSetting !== undefined && siteSetting !== null) {
      setUiSettings(siteSetting.ui_settings);
      setStatusFields(siteSetting.quick_access);
      setImageFields(siteSetting.slider_images);
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="tw-max-w-[1320px] tw-mx-auto tw-w-full">
        <h4 className="tw-mb-3 tw-text-[24px]">General Settings</h4>
        <hr />
        <div className="tw-flex tw-gap-[20px] tw-mt-3">
          <div className="tw-w-full">
            <label
              for="banner_bg"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Header background color
            </label>
            <br />
            <input
              type="color"
              className="tw-w-full tw-cursor-pointer"
              id="banner_bg"
              name="banner_bg"
              onChange={(e) => handleChangeColor(e, "header_bg")}
              value={uiSettings.header_bg}
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
          </div>
          <div className="tw-w-full">
            <label
              for="sidebar_bg"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Sidebar Background Color
            </label>
            <br />
            <input
              type="color"
              className="tw-w-full tw-cursor-pointer"
              id="sidebar_bg"
              name="sidebar_bg"
              onChange={(e) => handleChangeColor(e, "sidebar_bg")}
              value={uiSettings.sidebar_bg}
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
          </div>
        </div>
        <div className="tw-flex tw-gap-[20px] tw-mt-3">
          <div className="tw-w-full">
            <label
              for="bg_color"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Background color
            </label>
            <br />
            <input
              type="color"
              className="tw-w-full tw-cursor-pointer"
              id="bg_color"
              name="bg_color"
              onChange={(e) => handleChangeColor(e, "site_bg")}
              value={uiSettings.site_bg}
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
          </div>
          <div className="tw-w-full">
            <label
              for="header_text_color"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Header Text Color
            </label>
            <br />
            <input
              type="color"
              className="tw-w-full tw-cursor-pointer"
              id="header_text_color"
              name="header_text_color"
              onChange={(e) => handleChangeColor(e, "header_text_color")}
              value={uiSettings.header_text_color}
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
          </div>
        </div>
        <div className="tw-mt-3">
          <div className="tw-w-full">
            <label
              for="activate_form"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Activate Form
            </label>
            <br />
            <Input
              className="form-control img-input cursor-pointer"
              name="activate_form"
              type="text"
              id="activate_form"
              addonBefore="Form Embed Code"
              value={uiSettings.activation_form}
              onChange={(e) => {
                setUiSettings({
                  ...uiSettings,
                  activation_form: e.target.value,
                });
              }}
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
          </div>
        </div>
        <div className="tw-mt-3">
          <div className="tw-w-full">
            <label
              for="logo_image"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Header logo
            </label>
            <br />
            <input
              className="form-control img-input cursor-pointer"
              name="logo_image"
              type="file"
              id="logo_image"
              onChange={(e) =>
                handleChangeLogo(e, "header_logo", uiSettings.header_logo)
              }
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
            <div
              id="imageContainer"
              className="mt-2 tw-border-[#dee6ef] tw-border"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                width: "150px",
                minHeight: "90px",
                background: "gainsboro",
              }}
            >
              {uiSettings.header_logo === "" ? (
                <></>
              ) : (
                <img src={uiSettings.header_logo} alt="No preview" />
              )}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-gap-[20px] tw-mt-3">
          <div className="tw-w-full">
            <label
              for="header_image"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Home logo
            </label>
            <br />
            <input
              className="form-control img-input cursor-pointer"
              name="header_image"
              type="file"
              id="header_image"
              onChange={(e) =>
                handleChangeLogo(e, "home_logo", uiSettings.home_logo)
              }
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
            <div
              id="imageContainer"
              className="mt-2 tw-border-[#dee6ef] tw-border"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                width: "150px",
                minHeight: "90px",
              }}
            >
              {uiSettings.home_logo === "" ? (
                <></>
              ) : (
                <img src={uiSettings.home_logo} alt="No preview" />
              )}
            </div>
          </div>
          <div className="tw-w-full">
            <label
              for="incorpify_image"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Incorpify logo
            </label>
            <br />
            <input
              className="form-control img-input cursor-pointer"
              name="incorpify_image"
              type="file"
              id="incorpify_image"
              onChange={(e) =>
                handleChangeLogo(e, "incorpify_logo", uiSettings.incorpify_logo)
              }
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
            <div
              id="imageContainer"
              className="mt-2 tw-border-[#dee6ef] tw-border"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                width: "150px",
                minHeight: "90px",
              }}
            >
              {uiSettings.incorpify_logo === "" ? (
                <></>
              ) : (
                <img src={uiSettings.incorpify_logo} alt="No preview" />
              )}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-gap-[20px] tw-mt-3">
          <div className="tw-w-full">
            <label
              for="onboardify_image"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Onboardify logo
            </label>
            <br />
            <input
              className="form-control img-input cursor-pointer"
              name="onboardify_image"
              type="file"
              id="onboardify_image"
              onChange={(e) =>
                handleChangeLogo(
                  e,
                  "onboardify_logo",
                  uiSettings.onboardify_logo
                )
              }
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
            <div
              id="imageContainer"
              className="mt-2 tw-border-[#dee6ef] tw-border"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                width: "150px",
                minHeight: "90px",
              }}
            >
              {uiSettings.onboardify_logo === "" ? (
                <></>
              ) : (
                <img src={uiSettings.onboardify_logo} alt="No preview" />
              )}
            </div>
          </div>
          <div className="tw-w-full">
            <label
              for="governify_image"
              className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
            >
              Governify logo
            </label>
            <br />
            <input
              className="form-control img-input cursor-pointer"
              name="governify_image"
              type="file"
              id="governify_image"
              onChange={(e) =>
                handleChangeLogo(e, "governify_logo", uiSettings.governify_logo)
              }
            />
            <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
            <div
              id="imageContainer"
              className="mt-2 tw-border-[#dee6ef] tw-border"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                width: "150px",
                minHeight: "90px",
              }}
            >
              {uiSettings.governify_logo === "" ? (
                <></>
              ) : (
                <img src={uiSettings.governify_logo} alt="No preview" />
              )}
            </div>
          </div>
        </div>
        <div className="tw-w-full tw-border-[1px] tw-border-[#dee2e6] tw-rounded-[8px] tw-mt-3">
          <h5 className="tw-text-[#434343] tw-mb-3 tw-py-2 tw-flex tw-items-center ff-ws tw-bg-[#e7e7e7] tw-text-[18px] tw-justify-between tw-px-2 tw-border-b tw-border-[#dee2e6]">
            <span className="tw-font-[600]">Sliders images and urls:</span>
            <Button type="text" onClick={() => addImageField()}>
              +Add Field
            </Button>
          </h5>

          <div className="tw-px-[10px] tw-pb-[20px]">
            {imageFields.map((item, i) => {
              return (
                <div className="tw-w-full tw-flex tw-gap-[20px] tw-mt-[10px]">
                  <div className="tw-w-full">
                    <label
                      for=""
                      className="tw-block tw-font-[700] tw-text-[16px] tw-cursor-pointer"
                    >
                      Image
                    </label>
                    <br />
                    <input
                      className="form-control img-input cursor-pointer"
                      name=""
                      type="file"
                      onChange={(e) =>
                        handleChangeSliderImage(e, i, "slider_image_" + i)
                      }
                    />
                    <small className="tw-text-red-600 tw-text-left tw-ml-2"></small>
                    <div
                      id="imageContainer"
                      className="mt-2 tw-border-[#dee6ef] tw-border"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        width: "150px",
                        minHeight: "90px",
                      }}
                    >
                      {item.image === "" ? (
                        <></>
                      ) : (
                        <img src={item.image} alt="No preview" />
                      )}
                    </div>
                  </div>
                  <div className="tw-w-full">
                    <label
                      for=""
                      className="tw-block tw-font-[700] tw-text-[16px] "
                    >
                      URL
                    </label>
                    <br />
                    <input
                      type="url"
                      value={item.url}
                      placeholder="Enter Url"
                      className="tw-w-full tw-p-[5px] form-control"
                      onChange={(e) => handleSliderUrls(e.target.value, i)}
                    />
                  </div>
                  <Button
                    type="text"
                    className="tw-mt-[20px] tw-text-red-600"
                    onClick={() => removeImageFields(i, item.name, item.image)}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="tw-w-full tw-border-[1px] tw-border-[#dee2e6] tw-rounded-[8px] tw-mt-3">
          <h5 className="tw-text-[#434343] ff-ws tw-text-[18px] tw-bg-[#e7e7e7] tw-mb-3 tw-py-2 tw-flex tw-items-center tw-justify-between tw-px-2 tw-border-b tw-border-[#dee2e6]">
            <span className="tw-font-[600]">Quick Access Labels:</span>
            <Button type="text" onClick={() => addField()}>
              +Add Field
            </Button>
          </h5>

          <div className="tw-px-[10px] tw-pb-[20px]">
            {statusFields.map((item, i) => {
              return (
                <div className="tw-w-full tw-flex tw-gap-[20px] tw-mt-[10px]">
                  <div className="tw-w-full">
                    <label
                      for=""
                      className="tw-block tw-font-[700] tw-text-[16px]"
                    >
                      Label
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Label"
                      className="tw-w-full tw-p-[5px] form-control"
                      value={item.status}
                      onChange={(e) => changeStatus(i, e.target.value)}
                    />
                  </div>
                  <div className="tw-w-full">
                    <label
                      for=""
                      className="tw-block tw-font-[700] tw-text-[16px] "
                    >
                      Color
                    </label>
                    <br />
                    <input
                      type="color"
                      value={item.color}
                      className="tw-w-full"
                      onChange={(e) => changeColor(i, e.target.value)}
                    />
                  </div>
                  <Button
                    type="text"
                    className="tw-mt-[20px]"
                    onClick={() => removeField(i)}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="tw-mt-5">
          <Button
            className="tw-w-full tw-bg-[#005f83] tw-text-[#ffffff]"
            onClick={handleSubmit}
          >
            Save Setting
          </Button>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default AdminSetting;
