import React, { useEffect, useState } from "react";
import SliderWrapper from "./SliderWrapper";
import { useOutletContext } from "react-router-dom";
import { Button, Checkbox, Flex, Modal, Popover, Space, Table } from "antd";
import { fetcher, getUserInvites } from "./api/ApiHook";
import Loader from "./Loader";
import { toast, ToastContainer } from "react-toastify";
import { UPDATE_INVITE } from "./api/ApiConstants";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { UserEdit } from "../icons/Icons";
import { getDateAndTime, getExpiryDateAndTime } from "./utils/formatTime";

export const getStatus = (time, status) => {
  let date = new Date(time);
  let newStatus = "";
  let currentDate = new Date();
  let diff = (currentDate.getTime() - date.getTime()) / 1000;
  diff = diff / (60 * 60);
  if (diff > 72 && status.toLowerCase() === "pending") {
    newStatus = "Expired";
  } else if (status.toLowerCase() === "done") {
    newStatus = "Accepted";
  } else {
    newStatus = status[0].toUpperCase() + status.slice(1, status.length);
  }
  return newStatus;
};

const Invites = () => {
  const { searchValue, setSearchValue } = useOutletContext();
  const [siteSetting, setSiteSetting] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
  const [userObject, setUserObject] = useState({
    id: "",
    inviter_id: "",
    invitee_email: "",
    invitation_status: "",
    onboardify_status: "",
    incorpify_status: "",
    governify_status: "",
  });
  const [openModal, setOpenModal] = useState(false);
  let platformOption = JSON.parse(localStorage.getItem("platform"));
  useEffect(() => {
    if (localStorage.getItem("siteSetting")) {
      setSiteSetting(JSON.parse(localStorage.getItem("siteSetting")));
    }
  }, []);

  const getInvitedUsers = async () => {
    setLoading(true);
    let data = [];
    let users = await getUserInvites();
    users.forEach((item, index) => {
      console.log('item', item);
      data.push({
        s_no: index + 1,
        id: item.id,
        userId: item.inviter_id,
        userEmail: item.invitee_email[0].toUpperCase() + item.invitee_email.slice(1, item.invitee_email.length),
        incorpify: item.incorpify_status,
        onboardify: item.onboardify_status,
        governify: item.governify_status,
        invite_sent_date: getDateAndTime(item.created_at),
        invite_expire_date: getExpiryDateAndTime(item.created_at),
        status: getStatus(item.updated_at, item.invitation_status),
      });
    });

    setInvitedUsers([...data]);
    setLoading(false);
  };
  useEffect(() => {
    getInvitedUsers();
  }, []);

  const columns = [
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
      width: 200,
    },
    {
      title: "Incorpify",
      dataIndex: "incorpify",
      key: "incorpify",
      render: (_, record) => (
        <span>
          {record.incorpify == 1 ? (
            <CheckOutlined className="tw-text-green-600 tw-text-[20px]" />
          ) : (
            <CloseOutlined className="tw-text-red-600 tw-text-[20px]" />
          )}
        </span>
      ),
      width: 100,
    },
    {
      title: "Onboardify",
      dataIndex: "onboardify",
      key: "onboardify",
      render: (_, record) => (
        <span>
          {record.onboardify == 1 ? (
            <CheckOutlined className="tw-text-green-600 tw-text-[20px]" />
          ) : (
            <CloseOutlined className="tw-text-red-600 tw-text-[20px]" />
          )}
        </span>
      ),
      width: 120,
    },
    {
      title: "Governify",
      key: "governify",
      dataIndex: "governify",
      render: (_, record) => (
        <span>
          {record.governify == 1 ? (
            <CheckOutlined className="tw-text-green-600 tw-text-[20px]" />
          ) : (
            <CloseOutlined className="tw-text-red-600 tw-text-[20px]" />
          )}
        </span>
      ),
      width: 100,
    },
    {
      title: "Invite Sent Date",
      dataIndex: "invite_sent_date",
      key: "invite_sent_date",
      width: 180,
    },
    {
      title: "Invite Expiry Date",
      dataIndex: "invite_expire_date",
      key: "invite_expire_date",
      width: 180,
    },
    {
      title: "Invite Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span
          className={`tw-py-[5px] ff-ws tw-px-[10px] tw-rounded-2xl ${
            record.status === "Accepted"
              ? "tw-bg-green-200 tw-text-green-700"
              : record.status === "Pending"
              ? "tw-bg-orange-200 tw-text-orange-600"
              : "tw-bg-red-200 tw-text-red-700"
          }`}
        >
          {record.status}
        </span>
      ),
      width: 140,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              handleModal(record);
            }}
          >
            <Popover content={<span className="tw-p-[10px]">Edit</span>}>
              <EditOutlined style={{ fontSize: "20px" }} />
            </Popover>
          </Button>
        </Space>
      ),
      width: 80,
      fixed: "right",
    },
  ];

  const handleModal = (item) => {
    let ids = [];
    platformOption.map((platform) => {
      if (item.incorpify == 1 && platform.label.toLowerCase() === "incorpify") {
        ids.push(platform.value);
      }
      if (
        item.onboardify == 1 &&
        platform.label.toLowerCase() === "onboardify"
      ) {
        ids.push(platform.value);
      }
      if (item.governify == 1 && platform.label.toLowerCase() === "governify") {
        ids.push(platform.value);
      }
    });

    setDefaultValue([...ids]);
    setUserObject({
      id: item.userId,
      inviter_id: item.id,
      invitee_email: item.userEmail,
      invitation_status: item.status,
      onboardify_status: item.onboardify,
      incorpify_status: item.incorpify,
      governify_status: item.governify,
    });
    setOpenModal(true);
  };

  const handleProductStatus = (selectValue, value) => {
    let isSelected = 0;
    if (defaultValue.includes(value) && selectValue == value) {
      isSelected = 0;
    } else if (defaultValue.includes(value) && selectValue != value) {
      isSelected = 1;
    } else if (!defaultValue.includes(value) && selectValue == value) {
      isSelected = 1;
    } else if (!defaultValue.includes(value) && selectValue != value) {
      isSelected = 0;
    }

    return isSelected;
  };

  const changeProduct = (e) => {
    let object = userObject;
    platformOption.forEach((item) => {
      if (item.label.toLowerCase() === "incorpify") {
        object = {
          ...object,
          incorpify_status: handleProductStatus(e, item.value),
        };
      } else if (item.label.toLowerCase() === "governify") {
        object = {
          ...object,
          governify_status: handleProductStatus(e, item.value),
        };
      } else if (item.label.toLowerCase() === "onboardify") {
        object = {
          ...object,
          onboardify_status: handleProductStatus(e, item.value),
        };
      }
    });

    setUserObject({ ...object });
    let productAccess = defaultValue;
    if (productAccess.includes(e)) {
      productAccess = productAccess.filter((item) => item != e);
    } else {
      productAccess.push(e);
    }

    setDefaultValue([...productAccess]);
  };

  const checkStringIncludesProductName = (message) => {
    let include = false;
    if (
      message.toLowerCase().includes("governify") ||
      message.toLowerCase().includes("incorpify") ||
      message.toLowerCase().includes("onboardify")
    ) {
      include = true;
    }

    return include;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let payload = {
      id: userObject.inviter_id,
      update_data: {
        inviter_id: userObject.id,
        invitee_email: userObject.invitee_email,
        invitation_status: userObject.invitation_status,
        onboardify_status: userObject.onboardify_status,
        incorpify_status: userObject.incorpify_status,
        governify_status: userObject.governify_status,
      },
    };
    try {
      const res = await fetcher(UPDATE_INVITE, "POST", JSON.stringify(payload));
      if (res.success) {
        await getInvitedUsers();
        if (res?.revoked_domains && res?.revoked_domains.length > 0) {
          if (checkStringIncludesProductName(res.message)) {
            toast.success(
              res.message +
                " and revoked from: " +
                res?.revoked_domains.join(", ")
            );
          } else {
            toast.success(
              "Access revoked from: " + res?.revoked_domains.join(", ")
            );
          }
        } else {
          toast.success(res.message);
        }
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong!");
    } finally {
      setOpenModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="tw-flex tw-flex-col">
        <SliderWrapper searchValue={searchValue} siteSetting={siteSetting} />
        <div className="tw-flex tw-pb-[20px] tw-flex-col tw-gap-[20px] tw-mt-[40px]">
          <span className="2xl:tw-text-[1.25rem] tw-text-[1rem] tw-font-[500] tw-text-[#434343] ff-ws">
            Invited Users
          </span>
          <Table
            columns={columns}
            dataSource={invitedUsers}
            pagination={false}
            scroll={{ x: 768 }}
          />
        </div>
      </div>

      <Modal
        open={openModal}
        footer={null}
        centered
        onCancel={() => {
          setOpenModal(false);
        }}
        className="tasc-invite-form-modal"
      >
        <div className="tw-p-[40px]">
          <div className="tw-flex tw-justify-center tw-py-[20px]">
            <img src="/assets/1.png" alt="Tasc logo" width="240px" />
          </div>
          <div className="tw-text-center">
            <span className="tw-text-[26px] tw-text-[#434343] tw-font-[700]">
              Edit Member Access
            </span>
          </div>
          <div className="tw-py-[5px]">
            <span className="tw-font-[700] tw-text-[17px] tw-text-[#928F8F]">
              Choose to Grant and Revoke permission of a specific platform
            </span>
          </div>
          <div className="tw-flex tw-gap-[20px] tw-flex-col tw-py-[10px]">
            {platformOption.map((item) => {
              return (
                <div className="tw-leading-none tw-flex tw-items-center tw-gap-[10px]">
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      onChange={() => {
                        changeProduct(item.value);
                      }}
                      checked={defaultValue.includes(item.value)}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label className="tw-text-[17px] tw-text-[#928F8F] tw-cursor-pointer" onClick={() => {
                        changeProduct(item.value);
                      }}>
                    {item.label[0].toUpperCase() +
                      item.label.slice(1, item.label.length)}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="tw-mt-[20px]">
            <Button
              type="primary"
              className="tw-bg-[#28a745] !tw-p-[20px] hover:!tw-bg-[#28a745]"
              icon={<UserEdit />}
              onClick={handleSubmit}
            >
              Update Access
            </Button>
          </div>
        </div>
      </Modal>

      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Invites;
