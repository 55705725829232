import {
  GET_ALL_PROFILE_WITH_SERVICE,
  GET_COMMON_SITE_SETTING,
  GET_INVITE_DATA,
  GET_PLATFORM_DATA,
  GET_SITE_SETTING,
  GET_USER_DETAILS,
  GET_USER_PROFILE_STATUS,
} from "./ApiConstants";

export const fetcher = async (endpoint, method, payload = null) => {
  const token = getToken();
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `bearer ${token}`);
  let url = endpoint;

  let requestOptions = {
    method,
    headers: headers,
  };
  if (payload) {
    requestOptions.body = payload;
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return data;
};

export const getUserDetails = async () => {
  let token = getToken();
  if (token)
    try {
      const res = await fetcher(`${GET_USER_DETAILS}/${token}`, "GET");
      if (res.success) {
        localStorage.setItem("userEmail", res.data.email);
        localStorage.setItem("userName", res.data.name);
        localStorage.setItem("createdAt", res.data.created_at);
        localStorage.setItem("userId", res.data.user_id);
        localStorage.setItem("company", res.data.company);
        localStorage.setItem("phone", res.data.phone);
        localStorage.setItem("company_owner", res.data.company_owner);
        localStorage.setItem("domain", JSON.stringify(res.data.domain_access));
      }
    } catch (error) {
      console.log("error", error);
    }
};

export const getRole = () => {
  let role = localStorage.getItem("role") || "superAdmin";
  return role;
};

const getToken = () => {
  let token = localStorage.getItem("userToken");
  return token;
};

export const getSiteSettings = async () => {
  let data = [];
  try {
    const res = await fetcher(GET_SITE_SETTING, "GET");
    if (res.success) {
      data = res.data[0];
      localStorage.setItem("siteSetting", JSON.stringify(data));
    }
  } catch (error) {
    console.log("error", error);
  }

  return data;
};

export const getCommonSiteSetting = async () => {
  try {
    const response = await fetcher(GET_COMMON_SITE_SETTING, "GET");
    if (response.success) {
      localStorage.setItem(
        "incorpifyColumn",
        JSON.stringify({
          statusColumnId: response.data.incorpify.status,
          emailColumnId: response.data.incorpify.email,
        })
      );
      localStorage.setItem(
        "governifyColumn",
        JSON.stringify({
          catagoryColumnId: response.data.governify.head,
          emailColumnId: response.data.governify.email,
          statusColumnId: response.data.governify.status,
        })
      );
    }
  } catch (error) {
    console.log("error");
  }
};

export const getPlatformData = async () => {
  let data = [];
  try {
    const response = await fetcher(GET_PLATFORM_DATA, "GET");
    if (response.status) {
      data = response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const getUserProfileStatus = async (emailId) => {
  let data = "";
  try {
    const response = await fetcher(
      `${GET_USER_PROFILE_STATUS}/${emailId}`,
      "GET"
    );
    data = response;
  } catch (error) {
    console.log("error", error);
  }

  return data;
};

export const getUserInvites = async () => {
  let data = [];
  try {
    const response = await fetcher(GET_INVITE_DATA, "GET");
    if(response.data) {
      data = response.data;
    }
  } catch (error) {
    console.log("error", error);
  }
  return data;
};

export const fetchAllProfileServices = async () => {
  let data = [];
  try {
    const res = await fetcher(GET_ALL_PROFILE_WITH_SERVICE, "GET");
    if (res.status) {
      data = res.response[0].services;
    }
  } catch (error) {
    console.log("err", error);
  }

  return data;
};
