import React from "react";

const ErrorPage = () => {
	return (
		<div
			className="tw-flex tw-flex-col tw-items-center tw-justify-center"
			style={{ width: "100vw", height: "100vh" }}
		>
			<div className="tw-text-red-600 tw-font-[600] tw-text-[48px]">Something Went Wrong</div>
			<p className="tw-text-[24px]">Fall back error page 
				{/* <code className="">Boilerplate Fallback <small>Designed by -j</small></code> */}
			</p>
		</div>
	);
};

export default ErrorPage;
