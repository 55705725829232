import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

const CustomLeftArrow = ({ onClick }) => (
  <div className="custom-arrow left" onClick={onClick}>
    <LeftOutlined className="tw-text-[20px] md:tw-text-[30px] lg:tw-text-[40px]" />
  </div>
);

const CustomRightArrow = ({ onClick }) => (
  <div className="custom-arrow right" onClick={onClick}>
    <RightOutlined className="md:tw-text-[30px] lg:tw-text-[40px] tw-text-[20px] " />
  </div>
);
const HomeCarousel = ({ carouselData }) => {
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  let slidesToShow = 1;
  if (isLargeScreen) {
    slidesToShow = 3.3;
  } else if (isMediumScreen) {
    slidesToShow = 2;
  } else if (isSmallScreen) {
    slidesToShow = 1;
  }
  return (
    <>
      {carouselData.length > 0 ? (
        <Carousel
          arrows={true}
          dots={false}
          infinite={false}
          slidesToShow={slidesToShow}
          prevArrow={<CustomLeftArrow />}
          nextArrow={<CustomRightArrow />}
        >
          {carouselData.map((item) => {
            return (
              <div className="carousel-slide-wrapper">
                <div
                  className="carousel-slide"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div style={{ width: "100%" }}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        src={item.image}
                        alt="No Preview"
                        style={{ width: "100%" }}
                        className="tw-rounded-lg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      ) : (
        ""
      )}
    </>
  );
};

export default HomeCarousel;
