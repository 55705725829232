import { jwtDecode } from "jwt-decode";

export function isTokenValid(token) {
  if (!token) return { valid: false, error: "Token is empty" };

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return { valid: false, error: "Token is expired" };
    }

    return { valid: true, decoded };
  } catch (err) {
    return { valid: false, error: "Invalid token" };
  }
}

export function removeSessions() {
    localStorage.clear();
}

export function setUserDataCookie(data) {
  const jsonData = JSON.stringify(data);
  document.cookie = `userData=${encodeURIComponent(jsonData)}; path=/; max-age=1296000`; // Set for 15 days
}

function getCookie(name) {
  const cookieArray = document.cookie.split('; ');
  for (let cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) return decodeURIComponent(cookieValue);
  }
  return null;
}

export function getUserDataFromCookie() {
  const data = getCookie('userData');
  return data ? JSON.parse(data) : null;
}

export function isUserVerified(userId, browserName, system) {
  const userData = getUserDataFromCookie();
  if (!userData) return false;

  const user = userData.find(user => user.userId == userId);
  if (!user) return false;

  return user.user.some(session => session.browserName === browserName && session.system === system);
}



export function getBrowserInfo() {
  let userAgent = navigator.userAgent;
  let browserName, osType;

  // Determine browser name
  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    browserName = "Apple Safari";
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown Browser";
  }

  // Determine OS type
  if (userAgent.indexOf("Win") > -1) {
    osType = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    osType = "MacOS";
  } else if (userAgent.indexOf("Linux") > -1) {
    osType = "Linux";
  } else if (/Android/i.test(userAgent)) {
    osType = "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    osType = "iOS";
  } else {
    osType = "Unknown OS";
  }

  return {
    browser: browserName,
    os: osType,
  };
}