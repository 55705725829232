import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./Components/Fallbacks/ErrorPage";
import Login from "./Components/auth/Login";
import Register from "./Components/auth/Register";
import ForgotPassword from "./Components/auth/ForgetPassword";
import PanelWrapper from "./Components/Wrapper/PanelWrapper";
import Home from "./Components/Home";
import AdminSetting from "./Components/AdminSetting";
import AuthWrapper from "./Components/Wrapper/AuthWrapper";
import VerifyUser from "./Components/auth/VerifyUser";
import CreatePassword from "./Components/auth/CreatePassword";
import Invites from "./Components/Invites";
import TwoFactor from "./Components/TwoFactor";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    if(localStorage.getItem('userToken') == null || localStorage.getItem('userToken') == undefined || localStorage.getItem('userToken') == '') {
      sessionStorage.setItem("redirectUrl", window.location.href);
    }
  },[])

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Login />,
        },
        {
          path: "/signup",
          element: <Register />,
        },
        {
          path: "/forget-password",
          element: <ForgotPassword />,
        },
        {
          path: "/tasc360/verify",
          element: <VerifyUser />,
        },
        { path: "/reset-password", element: <CreatePassword /> },
        {
          path: "/login/two-factor",
          element: <TwoFactor />,
        },
      ],
    },
    {
      path: "/tasc360",
      element: <AuthWrapper />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <PanelWrapper />,
          children: [
            {
              path: "home",
              element: <Home />,
            },
            {
              path: "admin-setting",
              element: <AdminSetting />,
            },
            {
              path: "invites",
              element: <Invites />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <>NO PAGE FOUND</>,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
