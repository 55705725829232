import React from "react";
import HomeCarousel from "./HomeCarousel";

const SliderWrapper = ({ searchValue, siteSetting }) => {
  return (
    <>
      {searchValue === "" && (
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-[20px]">
          <div className="lg:tw-w-1/4  tw-w-full">
            <div className="tw-flex tw-flex-col tw-h-full">
              <span className="ff-ws tw-text-center lg:tw-text-left 2xl:tw-text-[2.125rem] tw-text-[1.875rem] text-inc-tundora tw-leading-none">
                Breaking
              </span>
              <span className="2xl:tw-text-[2.125rem] tw-text-center lg:tw-text-left tw-text-[1.875rem] ff-ws tw-font-[600] text-inc-tundora tw-ml-[4.375rem]">
                Insights
              </span>
            </div>
          </div>
          <div className="tw-w-full lg:tw-w-3/4 carousel-container">
            <HomeCarousel carouselData={siteSetting?.slider_images || []} />
          </div>
        </div>
      )}
    </>
  );
};

export default SliderWrapper;
